import { Button, Grid, Typography, useTheme } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import BackgroundSection from "../components/BackgroundSection";
import BookNowButton from "../components/BookNowButton";
import Footer from "../components/Footer";
import PageSection from "../components/PageSection";
import TopBanner from "../components/TopBanner";
import TopBar from "../components/TopBar";
import { purples } from "../theme";

const AboutPage = () => {
  const theme = useTheme();

  const { mainBackground, meaganPortrait, rafPortrait } =
    useStaticQuery(graphql`
      query {
        mainBackground: file(relativePath: { eq: "hurdles.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1024
              placeholder: BLURRED
              formats: [AUTO, JPG]
            )
          }
        }
        meaganPortrait: file(relativePath: { eq: "meagan2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 818
              placeholder: BLURRED
              formats: [AUTO, JPG]
            )
          }
        }
        rafPortrait: file(relativePath: { eq: "raf.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 818
              placeholder: BLURRED
              formats: [AUTO, JPG]
            )
          }
        }
      }
    `);

  return (
    <>
      <TopBar />
      <BackgroundSection
        backgroundPosition="center center"
        backgroundImage={getImage(mainBackground)}
      >
        <TopBanner heading="The Team" />
      </BackgroundSection>

      <PageSection
        id="meagan"
        title="Meagan"
        bgColor={purples[3]}
        labelColor="black"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <GatsbyImage image={getImage(meaganPortrait)} alt={"name"} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="body1" gutterBottom align="justify">
              My background as an Accredited Exercise Physiologist, coupled with
              my experience in the sub-elite setting has led to a strong
              interest in biomechanics, especially of the lower limb. I have a
              special interest in rehabilitation, specifically of the lower limb
              tendons, the hip and groin and running retraining. I place a
              strong emphasis on developing specialised strength and
              conditioning programs, which are sports specific in nature, to
              optimise injury rehabilitation and performance.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                aria-label="show more"
                color="secondary"
                variant="outlined"
              >
                <AnchorLink
                  to="/raf"
                  gatsbyLinkProps={{
                    style: {
                      textDecoration: "none",
                      fontWeight: "600",
                      color: theme.palette.secondary.main,
                      minWidth: "100%",
                    },
                  }}
                >
                  More details
                </AnchorLink>
              </Button>
            </div>
          </Grid>
        </Grid>
      </PageSection>
      <PageSection id="raf" title="Raf" bgColor={purples[3]} labelColor="black">
        <Grid container spacing={1}>
          <Grid item xs={12} md={9}>
            <Typography variant="body1" gutterBottom align="justify">
              My background as an Accredited Exercise Physiologist, coupled with
              my experience in the sub-elite setting has led to a strong
              interest in biomechanics, especially of the lower limb. I have a
              special interest in rehabilitation, specifically of the lower limb
              tendons, the hip and groin and running retraining. I place a
              strong emphasis on developing specialised strength and
              conditioning programs, which are sports specific in nature, to
              optimise injury rehabilitation and performance.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                aria-label="show more"
                color="secondary"
                variant="outlined"
              >
                <AnchorLink
                  to="/raf"
                  gatsbyLinkProps={{
                    style: {
                      textDecoration: "none",
                      fontWeight: "600",
                      color: theme.palette.secondary.main,
                      minWidth: "100%",
                    },
                  }}
                >
                  More details
                </AnchorLink>
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <GatsbyImage image={getImage(rafPortrait)} alt={"name"} />
          </Grid>
        </Grid>
      </PageSection>
      <BookNowButton />
      <Footer />
    </>
  );
};

export default AboutPage;
